@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-radial-gradient {
  background: radial-gradient(
    circle,
    rgba(177, 238, 241, 0.2) 5%, /* Lighter Blue */
    rgba(45, 172, 212, 0.2) 25%, /* Medium Blue */
    rgba(29, 77, 120, 0.2) 50%, /* Darker Blue */
    rgba(2, 3, 67, 0.2) 80% /* Deep Blue */
  );
}
